import DropdownProvider from './DropdownProvider';
import { Outlet, useParams } from 'react-router-dom';

function DropdownLayout() {
  const { id: pageId } = useParams(); // Retrieve the dynamic page ID

  return (
    <DropdownProvider pageId={pageId}>
      <Outlet />
    </DropdownProvider>
  );
}

export default DropdownLayout;