import React, { useState } from "react";
import "./Signup.css";
//import { FaLock } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { useNavigate } from "react-router-dom";
//import PopUp from "./PopUp";

function Signup() {
  const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Send a POST request to the serverless signup function
      const response = await fetch("/api/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username }),
      });

      if (response.ok) {
        console.log("User signed up successfully");
        navigate("/homepage"); // Navigate to Homepage on successful logi
      } else {
        const { error } = await response.json();
        console.error("Failed to sign up:", error);
      }
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  return (
    <div className="bg-logo flex-box max-width-height">
      <form className="form-signup" onSubmit={handleSubmit}>
        <label>
          <div className="icons-bg">
            <IoIosMail className="icons" color="white" size={"10px"} />
          </div>
          <input
            type="text"
            placeholder="Email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <label></label>
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
}

export default Signup;
