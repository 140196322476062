import React, { useState } from "react";
import "./ColorSelect.css"; // Import your CSS file

const ColorSelect = ({ colors, selectedColor, onColorChange }) => {
  const handleColorClick = (color) => {
    onColorChange(color); // Notify parent component of color change
  };

  return (
    <div className="color-select-container">
      <h3 className="color-select-title">Sélectionner la couleur</h3>
      <div className="color-buttons">
        {colors.map((color) => (
          <div className="color-button-container" key={color.label}>
            <button
              key={color.label}
              className={`color-button ${
                selectedColor === color.color ? "selected" : ""
              }`}
              style={{ backgroundColor: color.color }}
              onClick={() => handleColorClick(color.color)}
            >
              {selectedColor === color.color && (
                <span
                  className="color-dot"
                  style={{ backgroundColor: color.color }}
                ></span>
              )}
            </button>
            <div className="color-label">{color.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorSelect;
