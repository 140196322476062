import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Login";
import Homepage from "./HomePage";
import DropdownPage from "./DropdownPage"; // Import the DropdownPage component
import Signup from "./Signup";
import DropdownProvider from './DropdownProvider';
import UploadEmailForm from "./UploadEmailForm";
import ThanksPage from "./ThanksPage";
import PopUp from "./PopUp";
import DropdownLayout from './DropdownLayout';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    // Perform authentication, e.g., check username and password
    // For simplicity, I'll just set isAuthenticated to true
    setIsAuthenticated(true);
  };

  return (
    <DropdownProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/homepage" element={<Homepage />} />
          {/* Wrap only the /form/:id route with DropdownProvider */}
          <Route path="/form/:id" element={<DropdownLayout />}>
            <Route path="" element={<DropdownPage />} />
          </Route>
          <Route path="/send-email" element={<UploadEmailForm />} />
          <Route path="/thankspage" element={<ThanksPage />} />
          <Route path="/popup" element={<PopUp />} />
        </Routes>
      </Router>
    </DropdownProvider>
  );
};

export default App;
