import React from "react";
import "./ThanksPage.css";

const ThanksPage = () => {
  return (
    <div className="thankspage-container">
      <div className="header-section">
        {/* Add your featured content here */}
      </div>
      <div className="middle-section ">
        <div className="end-logo">
          <h2 className="h2-thankspage cookie-regular">vous dit merci !</h2>
        </div>
      </div>
      <div className="footer-homePage"></div>
    </div>
  );
};

export default ThanksPage;
