import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HomePage.css"; // Import the CSS file
import "./LoadingPage.css";
import { RiMailSendLine } from "react-icons/ri";
import { GoTrash } from "react-icons/go";
import { LuPlus } from "react-icons/lu";
import LoadingPage from "./LoadingPage";

//import { url } from "inspector";

const HomePage = () => {
  const [pages, setPages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const savedPages = JSON.parse(localStorage.getItem("pages")) || [];
    setPages(savedPages);
  }, []);

  const createNewPage = () => {
    const newPage = { id: Date.now().toString(), data: {}, name: "" };
    const updatedPages = [...pages, newPage];
    localStorage.setItem("pages", JSON.stringify(updatedPages));
    setPages(updatedPages);
    navigate(`/form/${newPage.id}`);
  };

  const deletePage = (id) => {
    const updatedPages = pages.filter((page) => page.id !== id);
    localStorage.setItem("pages", JSON.stringify(updatedPages));
    setPages(updatedPages);
  };

  const deleteAllForms = () => {
    // Clear the pages array
    const updatedPages = [];

    // Update localStorage
    localStorage.setItem("pages", JSON.stringify(updatedPages));

    // Update the state
    setPages(updatedPages);
  };

  return (
    <div className="homepage-container">
      <LoadingPage />
      {/* Add this line */}
      <div className="featured-section">
        <h2>MES FICHES DE DÉGUSTATION</h2>
        <div className="icone-main-verre"></div>
        {/* Add your featured content here */}
      </div>
      <div className="create-section">
        <ul>
          {pages.map((page) => (
            <li key={page.id} className="page-item">
              <Link to={`/form/${page.id}`}>
                {page.name || `Page ${page.id}`}
              </Link>
              <GoTrash onClick={() => deletePage(page.id)} />
            </li>
          ))}
        </ul>
      </div>
      <div className="footer-homePage">
        {" "}
        <div className="button-group">
          <div className="btn-rect" onClick={createNewPage}>
            <LuPlus size={40} className="btn-ajouter" />
            <p>Ajouter une fiche</p>
          </div>
          <div className="btn-rect" onClick={deleteAllForms}>
            <RiMailSendLine size={40} className="btn-cloturer" />
            <p>Clôturer les fiches</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
